<template>
    <section class="blockElement space bg-white">
        <div class="container"> 
            <div class="row align-items-center flex-row-reverse">
                <div class="col-12 col-lg-7 text-center">
                    <div class="zuluGuard">
                        <img src="/assets/images/automated-trading-hero-banner.png" :alt="$t('automatedTrading.automatedtext2')" :title="$t('automatedTrading.automatedtext2')" />
                    </div>
                </div>
                <div class="col-12 col-lg-5">
                    <h1 class="bold mb-md-3" v-html="$t('automatedTrading.automatedtext3')"></h1>
                    <p>{{$t('automatedTrading.automatedtext4')}}</p>
                    <p>{{$t('automatedTrading.automatedtext5')}}</p>
                    <div class="dualButton d-flex align-items-center mt-4">
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn large me-3 px-md-4">{{$t('automatedTrading.automatedtext6')}}</router-link>
                        <a v-else :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn large me-3 px-md-4">{{$t('automatedTrading.automatedtext6')}}</a>
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button bg-white zulu_btn rounded border-button">{{$t('automatedTrading.automatedtext7')}}</router-link>
                        <a v-else :href="static_vars.domainURL+'register/'" class="button bg-white zulu_btn rounded border-button">{{$t('automatedTrading.automatedtext7')}}</a>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    <section class="blockElement space automated2">
        <div class="container">
            <div class="row align-items-start">
                <div class="col-12 mb-4 mb-md-5 text-center">
                    <h2>{{$t('automatedTrading.automatedtext8')}}</h2>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card-body">
                        <span class="d-block mb-4"><v-lazy-image class="d-block h-70" src="/assets/images/Pasive-investment.png" :alt="$t('automatedTrading.automatedtext9')" :title="$t('automatedTrading.automatedtext10')" /></span>
                        <h4 class="bold">{{$t('automatedTrading.automatedtext11')}}</h4>  
                        <p>{{$t('automatedTrading.automatedtext12')}}</p>     
                    </div> 
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card-body">
                        <span class="d-block mb-4"><v-lazy-image class="d-block h-70" src="/assets/images/speed.png" :alt="$t('automatedTrading.automatedtext13')" :title="$t('automatedTrading.automatedtext14')" /></span>
                        <h4 class="bold">{{$t('automatedTrading.automatedtext15')}}</h4>  
                        <p>{{$t('automatedTrading.automatedtext16')}}</p>     
                    </div> 
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card-body">
                        <span class="d-block mb-4"><v-lazy-image class="d-block h-70" src="/assets/images/diversion.png" :alt="$t('automatedTrading.automatedtext17')" :title="$t('automatedTrading.automatedtext17')" /></span>
                        <h4 class="bold">{{$t('automatedTrading.automatedtext17')}}</h4>  
                        <p>{{$t('automatedTrading.automatedtext18')}}</p>     
                    </div> 
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card-body">
                        <span class="d-block mb-4"><v-lazy-image class="d-block h-70" src="/assets/images/back-testing.png" :alt="$t('automatedTrading.automatedtext19')" :title="$t('automatedTrading.automatedtext19')" /></span>
                        <h4 class="bold">{{$t('automatedTrading.automatedtext19')}}</h4>  
                        <p>{{$t('automatedTrading.automatedtext20')}}</p>     
                    </div> 
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card-body">
                        <span class="d-block mb-4"><v-lazy-image class="d-block h-70" src="/assets/images/no-emotions.png" :alt="$t('automatedTrading.automatedtext21')" :title="$t('automatedTrading.automatedtext21')" /></span>
                        <h4 class="bold">{{$t('automatedTrading.automatedtext21')}}</h4>  
                        <p>{{$t('automatedTrading.automatedtext22')}}</p>     
                    </div> 
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card-body">
                        <span class="d-block mb-4"><v-lazy-image class="d-block h-70" src="/assets/images/Works-on-your-behalf.png" :alt="$t('automatedTrading.automatedtext23')" :title="$t('automatedTrading.automatedtext23')" /></span>
                        <h4 class="bold">{{$t('automatedTrading.automatedtext23')}}</h4>  
                        <p>{{$t('automatedTrading.automatedtext24')}}</p>     
                    </div> 
                </div>
                <div class="col-12">
                    <div class="dualButton d-flex align-items-center justify-content-center mt-4">
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn large me-3 px-md-4">{{$t('automatedTrading.automatedtext6')}}</router-link>
                        <a v-else :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn large me-3 px-md-4">{{$t('automatedTrading.automatedtext6')}}</a>
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button bg-white zulu_btn rounded border-button">{{$t('automatedTrading.automatedtext7')}}</router-link>
                        <a v-else :href="static_vars.domainURL+'register/'" class="button bg-white zulu_btn rounded border-button">{{$t('automatedTrading.automatedtext7')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement space bg-white automated3">
        <div class="container">
            <div class="row mb-lg-5">
                <div class="col-12 col-lg-6">
                    <v-lazy-image class="d-block mx-auto" src="/assets/images/winner.png" :alt="$t('automatedTrading.automatedtext25')" :title="$t('automatedTrading.automatedtext25')" />
                </div>
                <div class="col-12 col-lg-6">
                    <h2 class="mb-4">{{$t('automatedTrading.automatedtext25')}}</h2>
                    <p>{{$t('automatedTrading.automatedtext26')}}</p>
                    <p>{{$t('automatedTrading.automatedtext27')}}</p>
                </div>
            </div>
            <div class="row flex-row-reverse">
                <div class="col-12 col-lg-6">
                    <v-lazy-image class="d-block mx-auto" src="/assets/images/trading-is-hard-creative.png" :alt="$t('automatedTrading.automatedtext28')" :title="$t('automatedTrading.automatedtext28')" />
                </div>
                <div class="col-12 col-lg-6">
                    <h2 class="mb-4">{{$t('automatedTrading.automatedtext28')}}</h2>
                    <p>{{$t('automatedTrading.automatedtext29')}}</p>
                    <p>{{$t('automatedTrading.automatedtext30')}}</p>
                </div>
                
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="dualButton d-flex align-items-center justify-content-center mt-4">
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn large me-3 px-md-4">{{$t('automatedTrading.automatedtext6')}}</router-link>
                        <a v-else :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn large me-3 px-md-4">{{$t('automatedTrading.automatedtext6')}}</a>
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button bg-white zulu_btn rounded border-button">{{$t('automatedTrading.automatedtext7')}}</router-link>
                        <a v-else :href="static_vars.domainURL+'register/'" class="button bg-white zulu_btn rounded border-button">{{$t('automatedTrading.automatedtext7')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement space automated4">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-center mb-md-5 pb-4">{{$t('automatedTrading.automatedtext31')}}</h2>
                    <ul class="listCounter">
                        <li class="d-flex align-items-start pb-4">
                            <span class="customXpad d-flex justify-content-center">
                                <v-lazy-image width="150" class="d-block" src="/assets/images/open-account.svg" :alt="$t('automatedTrading.automatedtext32')" :title="$t('automatedTrading.automatedtext32')" />
                            </span>
                            <label class="d-block">
                                <h4 class="mb-2 bold">{{$t('automatedTrading.automatedtext32')}}</h4>
                                <p>{{$t('automatedTrading.automatedtext33')}}</p>
                            </label>
                        </li>
                        <li class="d-flex align-items-start pb-4">
                            <span class="customXpad d-flex justify-content-center">
                                <v-lazy-image width="150" class="d-block" src="/assets/images/top-traders-tab.svg" :alt="$t('automatedTrading.automatedtext34')" :title="$t('automatedTrading.automatedtext34')" />
                            </span>
                            <label class="d-block">
                                <h4 class="mb-2 bold">{{$t('automatedTrading.automatedtext34')}}</h4>
                                <p>{{$t('automatedTrading.automatedtext35')}}</p>
                            </label>
                        </li>
                        <li class="d-flex align-items-start pb-4">
                            <span class="customXpad d-flex justify-content-center">
                                <v-lazy-image width="150" class="d-block" src="/assets/images/Select-trader.svg" :alt="$t('automatedTrading.automatedtext36')" :title="$t('automatedTrading.automatedtext36')" />
                            </span>
                            <label class="d-block">
                                <h4 class="mb-2 bold">{{$t('automatedTrading.automatedtext36')}}</h4>
                                <p>{{$t('automatedTrading.automatedtext37')}}</p>
                            </label>
                        </li>
                        <li class="d-flex align-items-start pb-4">
                            <span class="customXpad d-flex justify-content-center">
                                <v-lazy-image width="150" class="d-block" src="/assets/images/Start-journey.svg" :alt="$t('automatedTrading.automatedtext38')" :title="$t('automatedTrading.automatedtext38')" />
                            </span>
                            <label class="d-block">
                                <h4 class="mb-2 bold">{{$t('automatedTrading.automatedtext38')}}</h4>
                                <p>{{$t('automatedTrading.automatedtext39')}}</p>
                            </label>
                        </li>
                    </ul>

                </div>
                    <div class="col-12">
                        <div class="dualButton d-flex align-items-center justify-content-center mt-4">
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn large me-3 px-md-4">{{$t('automatedTrading.automatedtext6')}}</router-link>
                        <a v-else :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn large me-3 px-md-4">{{$t('automatedTrading.automatedtext6')}}</a>
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button bg-white zulu_btn rounded border-button">{{$t('automatedTrading.automatedtext7')}}</router-link>
                        <a v-else :href="static_vars.domainURL+'register/'" class="button bg-white zulu_btn rounded border-button">{{$t('automatedTrading.automatedtext7')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement space bg-white automated5">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-center mb-md-5 pb-4">{{$t('automatedTrading.automatedtext40')}}</h2>
                </div>
                <div class="col-12 col-md-6 mb-4">
                    <div class="d-flex align-items-start">
                        <span class="automated me-3"><v-lazy-image class="d-block" src="/assets/images/the-automator.png" :alt="$t('automatedTrading.automatedtext41')" :title="$t('automatedTrading.automatedtext41')" /></span>
                        <div class="contentCalc">
                            <h4 class="bold">{{$t('automatedTrading.automatedtext41')}}</h4>  
                            <p>{{$t('automatedTrading.automatedtext42')}}</p>
                        </div> 
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-4">
                    <div class="d-flex align-items-start">
                        <span class="automated me-3"><v-lazy-image class="d-block" src="/assets/images/Faster-calculations.png" :alt="$t('automatedTrading.automatedtext43')" :title="$t('automatedTrading.automatedtext43')" /></span>
                        <div class="contentCalc">
                            <h4 class="bold">{{$t('automatedTrading.automatedtext43')}}</h4>  
                            <p>{{$t('automatedTrading.automatedtext44')}}</p>
                        </div> 
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-4">
                    <div class="d-flex align-items-start">
                        <span class="automated me-3"><v-lazy-image class="d-block" src="/assets/images/Non-stop.png" :alt="$t('automatedTrading.automatedtext45')" :title="$t('automatedTrading.automatedtext45')" /></span>
                        <div class="contentCalc">
                            <h4 class="bold">{{$t('automatedTrading.automatedtext45')}}</h4>  
                            <p>{{$t('automatedTrading.automatedtext46')}}</p>
                        </div> 
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-4">
                    <div class="d-flex align-items-start">
                        <span class="automated me-3"><v-lazy-image class="d-block" src="/assets/images/back-testing.png" :alt="$t('automatedTrading.automatedtext47')" :title="$t('automatedTrading.automatedtext47')" /></span>
                        <div class="contentCalc">
                            <h4 class="bold">{{$t('automatedTrading.automatedtext47')}}</h4>  
                            <p>{{$t('automatedTrading.automatedtext48')}}</p>
                        </div> 
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-4">
                    <div class="d-flex align-items-start">
                        <span class="automated me-3"><v-lazy-image class="d-block" src="/assets/images/integration.png" :alt="$t('automatedTrading.automatedtext49')" :title="$t('automatedTrading.automatedtext49')" /></span>
                        <div class="contentCalc">
                            <h4 class="bold">{{$t('automatedTrading.automatedtext49')}}</h4>  
                            <p>{{$t('automatedTrading.automatedtext50')}}</p>
                        </div> 
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-4">
                    <div class="d-flex align-items-start">
                        <span class="automated me-3"><v-lazy-image class="d-block" src="/assets/images/Formula.png" :alt="$t('automatedTrading.automatedtext51')" :title="$t('automatedTrading.automatedtext51')" /></span>
                        <div class="contentCalc">
                            <h4 class="bold">{{$t('automatedTrading.automatedtext51')}}</h4>  
                            <p>{{$t('automatedTrading.automatedtext52')}}</p>
                        </div> 
                    </div>
                </div>
                <div class="col-12">
                    <div class="dualButton d-flex align-items-center justify-content-center mt-4">
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn large me-3 px-md-4">{{$t('automatedTrading.automatedtext6')}}</router-link>
                        <a v-else :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn large me-3 px-md-4">{{$t('automatedTrading.automatedtext6')}}</a>
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button bg-white zulu_btn rounded border-button">{{$t('automatedTrading.automatedtext7')}}</router-link>
                        <a v-else :href="static_vars.domainURL+'register/'" class="button bg-white zulu_btn rounded border-button">{{$t('automatedTrading.automatedtext7')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement space faqs automated6">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="mb-md-4 pb-4">{{$t('automatedTrading.automatedtext53')}}</h2>
                </div>
                <div class="col-12">
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                {{$t('automatedTrading.automatedtext54')}}
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">{{$t('automatedTrading.automatedtext55')}}</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                {{$t('automatedTrading.automatedtext56')}}
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">{{$t('automatedTrading.automatedtext57')}}</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                {{$t('automatedTrading.automatedtext58')}}
                                </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">{{$t('automatedTrading.automatedtext59')}}</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                {{$t('automatedTrading.automatedtext60')}}
                                </button>
                            </h2>
                            <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">{{$t('automatedTrading.automatedtext61')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
    }
</script>